
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { api as GraphQLAPI } from 'monaco-graphql'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { GraphQLClient } from 'graphql-request'
import { GRAPHQL_URL } from '@/lib/api_repository'
import { Field } from '@/types/graphql'
import { generateQuery } from '@/lib/queryGenerator'

@Component
export default class Graphiql extends Vue {
  @Prop({ required: true }) field: Field;
  @Prop({ required: true }) schema: string;
  @Prop({ required: true }) auth: string;
  @Prop({ required: false }) cols: number;
  error: number | null = null
  errorMessageText = ''
  requiredOnly = true
  result = false
  running = false
  private editor: monaco.editor.IStandaloneCodeEditor = null
  private fieldType: 'query' | 'mutation' = 'query'
  private query = ''
  private resultEditor: monaco.editor.IStandaloneCodeEditor = null
  private resizeTimer: number

  mounted () {
    GraphQLAPI.setSchema(this.schema)
    this.createQueryEditor()
    this.createResultEditor()
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = window.setTimeout(this.adjustEditor, 100)
    })
  }

  destroyed () {
    this.editor.dispose()
  }

  @Watch('query')
  onQueryChanged () {
    if (this.editor) this.editor.setValue(this.query)
  }

  @Watch('cols')
  onColsChanged () {
    this.updateQueryHeight()
    this.updateResultHeight()
  }

  @Watch('field')
  onFieldChanged () {
    this.setQuery(true)
  }

  @Watch('requiredOnly')
  onRequiredOnlyChanged () {
    this.setQuery()
  }

  get errorMessage (): string {
    if (this.error === 404) return 'Not found'
    else if (this.error === 401) return 'Unauthorized'
    else if (this.errorMessageText) return this.errorMessageText
    else return 'An error occurred'
  }

  async request () {
    if (!this.auth) return

    this.running = true
    this.error = null
    const query = this.editor.getValue()
    const client = new GraphQLClient(GRAPHQL_URL)
    client.setHeader('authorization', `Bearer ${this.auth}`)
    try {
      const response = await client.request(query)
      this.result = true
      this.resultEditor.setValue(JSON.stringify(response, null, 2))
      await this.$nextTick()
      this.resultEditor.layout()
    } catch (e) {
      console.error(e)
      this.errorMessageText = e.response?.errors[0]?.message
      if (/404/.test(e.response?.errors[0]?.message)) this.error = 404
      else this.error = e.response?.status || 418
    } finally {
      this.running = false
    }
  }

  private createQueryEditor () {
    if (this.editor) return this.editor

    this.editor = monaco.editor.create(this.$refs.query as HTMLElement, {
      value: this.query,
      language: 'graphqlDev',
      automaticLayout: true,
      scrollBeyondLastLine: false,
      formatOnPaste: true,
      formatOnType: true,
      wordWrap: 'on',
      wrappingStrategy: 'advanced',
      minimap: {
        enabled: false
      },
      theme: 'vs-dark'
    })
    this.updateQueryHeight()
  }

  private createResultEditor () {
    if (this.resultEditor) return this.resultEditor

    this.resultEditor = monaco.editor.create(this.$refs.result as HTMLElement, {
      value: '',
      language: 'json',
      formatOnPaste: true,
      scrollBeyondLastLine: false,
      formatOnType: true,
      minimap: {
        enabled: false
      },
      theme: 'vs-dark'
    })
    this.updateResultHeight()
  }

  private setQuery (refreshType?: boolean): void {
    if (refreshType) this.fieldType = this.$route.name as any
    this.query = generateQuery(this.fieldType, this.field, this.requiredOnly)
  }

  private adjustEditor (): void {
    this.updateQueryHeight()
    this.updateResultHeight()
  }

  private editorHeight (): number {
    // Divide by magic number to fit on 1080p screens as well as utilize more space on larger screens
    return Math.floor(window.innerHeight / 3.5)
  }

  private updateQueryHeight (): void {
    this.editor.layout({
      width: (this.$refs.query as HTMLElement).offsetWidth,
      height: this.editorHeight()
    })
  }

  private updateResultHeight (): void {
    this.resultEditor.layout({
      width: (this.$refs.result as HTMLElement).offsetWidth,
      height: this.editorHeight()
    })
  }
}
