import { Arg, Field, FieldType, UnionType } from '@/types/graphql'
import { returnType } from '@/lib/typeConverter'

const generateArg = (arg: Arg, requiredOnly = true): string => {
  let defaultValue = arg.defaultValue
  if (arg.type.value === 'String') {
    defaultValue = `"${defaultValue ?? ''}"`
  } else if (arg.type.value === 'Int') {
    defaultValue = defaultValue ?? 0
  } else if (arg.type.name === 'collection') {
    const type = (arg.type.value as FieldType).value
    defaultValue = `[${defaultValue?.map(val => type === 'String' ? `"${val}"` : val).join(', ') || ''}]`
  } else if (arg.type.name === 'input') {
    const args = arg.type.args.map(
      a => requiredOnly && a.type.nullable ? null : generateArg(a, requiredOnly)
    ).filter(a => a)
    defaultValue = `{ ${args.join(', ')} }`
  } else if (arg.type.name === 'enum') {
    defaultValue = defaultValue || arg.type.enums[0].value
  }
  return `${arg.name}: ${defaultValue}`
}

const findFields = (field: Field | FieldType): Field[] => {
  if ('fields' in field) {
    return field.fields
  } else if ('type' in field && field.type.name === 'collection') {
    return (field.type.value as FieldType).fields || []
  } else {
    return returnType(field as Field).fields || []
  }
}

const generateUnionTypes = (union: UnionType, requiredOnly = true, indent = 0): string => {
  const typeStr: string[] = []
  const fieldReqs: { [key: string]: boolean } = {}
  union.types.forEach(type => {
    if (type.description === '_empty') return

    typeStr.push(`${indentStr(2 + indent)}...on ${type.value} {`)
    type.fields.forEach(field => {
      let f = field
      if (Object.keys(fieldReqs).includes(field.name) && (fieldReqs[field.name] || field.type.nullable)) {
        f = { ...field, name: `${field.name}NonNull: ${field.name}` }
      }
      fieldReqs[field.name] = fieldReqs[field.name] || field.type.nullable
      typeStr.push(`${indentStr(4 + indent)}${generateField(f, requiredOnly, 2 + indent)}`)
    })
    typeStr.push(`${indentStr(2 + indent)}}`)
  })
  return typeStr.join('\n')
}

const indentStr = (spaces: number): string => {
  return ''.padStart(spaces)
}

const generateField = (field: Field | FieldType, requiredOnly = true, indent = 0): string => {
  indent += 2

  const args = field.args
    .map(arg => requiredOnly && arg.type.nullable ? null : generateArg(arg, requiredOnly))
    .filter(a => a) || []
  const argStr = args.length ? `(${args.join(', ')})` : ''

  if ('type' in field && field.type.description === '_empty') {
    return ''
  }

  let fieldStr = `${field.name}${argStr}`

  if ('type' in field && field.type.name === 'union') {
    const unionString = generateUnionTypes(field.type as UnionType, requiredOnly, indent)

    if (unionString.length === 0) return ''

    fieldStr = `${fieldStr} {\n${unionString}\n${indentStr(indent)}}`
  }

  const fields = findFields(field)
  if (fields.length) {
    fieldStr += ' {\n'
    fields.forEach(f => {
      const subfield = generateField(f, requiredOnly, indent)
      if (subfield.length === 0) return
      return (fieldStr += `${indentStr(2 + indent)}${subfield}\n`)
    })
    fieldStr += `${indentStr(indent)}}`
  } else if ('type' in field && typeof field.type.value === 'object' && field.type.value.name === 'union') {
    return ''
  }

  return fieldStr
}

export const generateQuery = (type: 'query' | 'mutation', field: Field, requiredOnly = true): string => {
  return `${type} {\n  ${generateField(field, requiredOnly)}\n}\n`
}
