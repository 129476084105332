



















import { Component, Vue, Prop } from 'vue-property-decorator'
import apis from '@/lib/api_repository'
import TypeTable from '@/components/graphql/shared/TypeTable.vue'
import ArgTable from '@/components/graphql/shared/ArgTable.vue'
import { readableType } from '@/lib/typeConverter'
import { FieldType } from '@/types/graphql'
import { routeTo } from '@/lib/routeBuilder'

@Component({ components: { TypeTable, ArgTable } })
export default class Type extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) api!: string;

  get instance () {
    const type = this.client.getType(this.name)
    return this.client.ready && readableType(type, 0)
  }

  private get client () {
    return apis.client(this.api)
  }

  routeTo (fieldType: FieldType) {
    return routeTo(this.api, { type: fieldType })
  }
}
