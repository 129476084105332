import { Arg, Field } from '@/types/graphql'
import { Location } from 'vue-router'
import { typeValue } from '@/lib/typeConverter'

export const routeTo = (api: string, field: Pick<Field, 'type'> | Pick<Arg, 'type'>): Location => {
  let name = 'type'
  if (field.type.name === 'collection') name = 'input'
  if (['enum', 'scalar', 'input'].includes(field.type.name)) name = field.type.name

  return { name, params: { api, name: typeValue(field) } }
}
