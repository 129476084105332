























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Field, FieldType, GQLField } from '@/types/graphql'
import { transformType, returnType } from '@/lib/typeConverter'
import apis from '@/lib/api_repository'
import TypeTable from '@/components/graphql/shared/TypeTable.vue'
import ArgTable from '@/components/graphql/shared/ArgTable.vue'
import { filterXSS } from 'xss'

@Component({ components: { TypeTable, ArgTable } })
export default class RootField extends Vue {
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) type!: 'query' | 'mutation'
  @Prop({ required: true }) api!: string

  get sanitizedDescription () {
    return filterXSS(this.rootField.description, { allowList: { a: ['href'] } })
  }

  get rootField (): Field {
    return this.client.ready && transformType(this.getRootField(), 0)
  }

  get returnType (): FieldType {
    return returnType(this.rootField)
  }

  private get client () {
    return apis.client(this.api)
  }

  tryField (): void {
    this.$emit('try-field', this.rootField)
  }

  private getRootField (): GQLField {
    return this.type === 'query' ? this.client.getQuery(this.name) : this.client.getMutation(this.name)
  }
}
