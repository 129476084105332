





import { Component, Vue } from 'vue-property-decorator'
import { Api } from '@/lib/api'
import { saveAs } from 'file-saver'

@Component({})
export default class GraphQLSchema extends Vue {
  finalText = ''
  async created () {
    let scope = ''
    switch (this.$route.params.api) {
      case 'ksat':
        scope = 'public_kmsat'
        break
      case 'ksat-partner':
        scope = 'partner_public_kmsat'
        break
      case 'passwordiq':
        scope = 'public_piq'
        break
      case 'securitycoach':
        scope = 'public_security_coach'
        break
    }

    const schema = new Api(`${process.env.VUE_APP_GRAPHQL_URL}?scope=${scope}`)
    await schema.fetchSchema()

    this.finalText = JSON.stringify(schema.rawSchema, null, 2)

    saveAs(new Blob([this.finalText], { type: 'application/json' }), 'schema.json')
  }
}
