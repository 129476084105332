import { GraphQLOutputType, GraphQLInputType, GraphQLArgument } from 'graphql'
import { Field, FieldType, GQLField, Arg } from '@/types/graphql'
import { GraphQLReadableReturnType } from '@/lib/readableReturnType'

export const returnType = (field: Field): FieldType => {
  return ('ofType' in field.type) ? field.type.ofType : field.type
}

export const fetchFields = (fieldType: FieldType, level: number): Field[] | Arg[] => {
  return Object.keys(fieldType._fields || {}).map(fieldName => transformType(fieldType._fields[fieldName], level))
}

export const readableType = (type: GraphQLOutputType | GraphQLInputType, level: number): FieldType => {
  return new GraphQLReadableReturnType(type, level).output
}

export function transformType<T>(instance: T, level: number): T extends GQLField ? Field : Arg
// eslint-disable-next-line no-redeclare
export function transformType (instance: GQLField | GraphQLArgument, level: number): Field | Arg {
  if ('args' in instance) {
    const args = instance.args.map(arg => transformType(arg, level)).sort((a, b) => a.name.localeCompare(b.name))
    return { ...instance, type: readableType(instance.type, level), args }
  } else {
    return { ...instance, type: readableType(instance.type, level) }
  }
}

export const typeValue = (field: Pick<Field, 'type'> | Pick<Arg, 'type'>): string => {
  if (field.type.name === 'collection') return (field.type.value as FieldType).value as string
  return field.type.value as string
}
