





































import { Component, Vue, Watch } from 'vue-property-decorator'
import repository, { Endpoint } from '@/lib/api_repository'
import ApiName from '@/components/shared/ApiName.vue'

@Component({ components: { ApiName } })
export default class App extends Vue {
  selectedApi: Endpoint | null = null

  @Watch('$route', { immediate: true })
  onRouteChanged () {
    this.selectedApi = repository.getEndpoint(this.$route.params.api)
  }

  get apis () {
    return repository.endpoints
  }
}
