import { render, staticRenderFns } from "./TypeTable.vue?vue&type=template&id=599a5c23&scoped=true&lang=pug&"
import script from "./TypeTable.vue?vue&type=script&lang=ts&"
export * from "./TypeTable.vue?vue&type=script&lang=ts&"
import style0 from "./TypeTable.vue?vue&type=style&index=0&id=599a5c23&lang=scss&scoped=true&"
import style1 from "./TypeTable.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599a5c23",
  null
  
)

export default component.exports