




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Arg, Field } from '@/types/graphql'
import FieldName from '@/components/graphql/shared/FieldName.vue'
import FieldArg from '@/components/graphql/shared/FieldArg.vue'
import { BvTableFieldArray } from 'bootstrap-vue'

@Component({ components: { FieldName, FieldArg } })
export default class Type extends Vue {
  @Prop({ required: true }) field!: Field

  get fields (): BvTableFieldArray {
    return [
      'attribute',
      'description',
      'nestedAttrs',
      { key: 'default', class: 'text-center' },
      { key: 'required', class: 'text-center' }
    ]
  }

  getDefaultValue (arg: Arg): any {
    if (arg.defaultValue === undefined) return

    return arg.type.value === 'String' ? `"${arg.defaultValue}"` : arg.defaultValue
  }
}
