




















import { Component, Vue, Prop } from 'vue-property-decorator'
import apis from '@/lib/api_repository'
import { Location } from 'vue-router'

@Component
export default class GraphQLSidebar extends Vue {
  @Prop({ required: true }) name!: string;

  get menuItems () {
    return [
      { type: 'page', name: 'Introduction' },
      { type: 'page', name: 'Base URL' },
      { type: 'page', name: 'GraphQL Schema', disabled: this.name === 'phisher' },
      { type: 'page', name: 'Authentication' },
      { type: 'page', name: 'Pagination', disabled: this.name !== 'phisher' },
      { type: 'query', name: 'Queries', children: this.queries },
      { type: 'mutation', name: 'Mutations', children: this.mutations },
      { type: 'type', name: 'Types', children: this.types },
      { type: 'input', name: 'Inputs', children: this.inputs },
      { type: 'enum', name: 'Enums', children: this.enums },
      { type: 'scalar', name: 'Scalars', children: this.scalars },
      { type: 'page', name: 'Terms of Use' }
    ].filter(item => !item.disabled)
  }

  private get api () {
    return apis.client(this.name)
  }

  private get apiVisible () {
    return this.name === this.$route.params.api
  }

  private get enums () {
    return this.api?.enums || []
  }

  private get scalars () {
    return this.api?.scalars || []
  }

  private get types () {
    return this.api?.objects || []
  }

  private get inputs () {
    return this.api?.inputs || []
  }

  private get mutations () {
    return this.api?.mutations || []
  }

  private get queries () {
    return this.api?.queries || []
  }

  routeTo (type: string, name: string): Location {
    return { name: type, params: { api: this.name, name: name.replace(/\s/g, '-') } }
  }

  scopedId (section: string): string {
    return `${this.name}-${section}`
  }

  visible (type: string): boolean {
    return this.apiVisible && [this.$route.name, this.$route.params.type].includes(type)
  }
}
